@tailwind base;
@tailwind components;
@tailwind utilities;

/* * { border: 1px solid black; } */


.slick-prev:before, .slick-next:before {
  color: #000;
  visibility: hidden;
}

.slick-arrow {
  visibility: hidden;
}

/* @layer base { */
  @font-face {
    font-family: Open-Sans;
    font-weight: 400;
    src: url(./assets/fonts/Open-Sans/OpenSans-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Open-Sans;
    font-weight: 600;
    src: url(./assets/fonts/Open-Sans/OpenSans-SemiBold.ttf) format("truetype");
  }
  @font-face {
    font-family: Open-Sans;
    font-weight: 700;
    src: url(./assets/fonts/Open-Sans/OpenSans-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: Open-Sans;
    font-weight: 800;
    src: url(./assets/fonts/Open-Sans/OpenSans-ExtraBold.ttf) format("truetype");
  }
  @font-face {
    font-family: Lemon-Milk;
    font-weight: 300;
    src: url(./assets/fonts/Lemon-Milk/LEMONMILK-Light.otf) format("opentype");
  }
  @font-face {
    font-family: Lemon-Milk;
    font-weight: 400;
    src: url(./assets/fonts/Lemon-Milk/LEMONMILK-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: Lemon-Milk;
    font-weight: 500;
    src: url(./assets/fonts/Lemon-Milk/LEMONMILK-Medium.otf) format("opentype");
  }
  @font-face {
    font-family: Lemon-Milk;
    font-weight: 600;
    src: url(./assets/fonts/Lemon-Milk/LEMONMILK-Bold.otf) format("opentype");
  }

.image-shadow:hover {
  box-shadow: 10 10 10px 0 rgba(127, 127, 127, 0.75);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}